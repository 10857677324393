import React, {Fragment, lazy, Suspense, useEffect, useState} from 'react';
import {ExclamationCircleIcon, InformationCircleIcon} from '@heroicons/react/20/solid'
import './styles/global.scss';
import Register, {
    NotFound,
    RegisterContent,
    RegisterFinal,
    RegisterFooter,
    RegisterHeader,
    RegisterRoot,
} from "./pages/register";
import {useDispatch} from "react-redux";
import Spinner from "./components/Spinner";
import {Link, Navigate, Route, Routes, useParams} from "react-router-dom";
import {auth, firestore} from "./firebase";
import {Trans, useTranslation} from "react-i18next";
import {setGlobalLoader} from "./store/loader";
import {Transition} from '@headlessui/react'
import {Enroll2FA, Login} from "./pages/login";
import Quote from "./pages/quote";
import {SignInAs} from "./pages/sign-in-as";
import {usePageViews} from "./utils";
import LostPassword from "./pages/lost-password";
import ConfirmationSent from "./pages/confirmation-sent";
import {multiFactor, onAuthStateChanged} from "firebase/auth";
import {api} from "./config";
import {doc, onSnapshot} from "firebase/firestore";
import useSWRSubscription from "swr/subscription";
import JumperGame from "./components/jumperGame";
import useSWR, {useSWRConfig} from "swr";
import {resetAuthorizationHeaders} from "./interceptors";

const LazyApp = lazy(() => import("./app"));

function useOnlineStatus() {
    const [isOnline, setIsOnline] = useState(navigator.onLine !== false);

    useEffect(() => {
        const updateOnlineStatus = () => {
            setIsOnline(navigator.onLine !== false);
        }

        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);

        return () => {
            window.removeEventListener('online', updateOnlineStatus);
            window.removeEventListener('offline', updateOnlineStatus);
        }
    }, []);

    return isOnline;
}

function RequireAuth({user, children}) {
    if (user === false) return <Spinner fullHeight/>
    if (user) {
        const mf = multiFactor(user)
        if (user.emailVerified && mf.enrolledFactors?.length > 0) {
            if (!mf.enrolledFactors.find(factor => factor.factorId === "phone")) return <Navigate to="/enroll"/>
            //return await authorizeRequest(user)
        } else return <Navigate to="/verify-email"/>
    } else return <Navigate to="/"/>
    return children
}

function RequireNonAuth({user, children}) {
    const {data, isLoading} = useSWR('user-session+' + (user?.uid || ""), () => {
        //const user = auth.currentUser
        if (user) {
            if (user.emailVerified && multiFactor(user).enrolledFactors?.length > 0) {
                return multiFactor(user).getSession().then(multiFactorSession => {
                    if (multiFactorSession?.type === "enroll") {
                        // signed in
                        return "u/dashboard"
                    }
                    return true
                })
            }
        }
        return true
    })

    if (data === "u/dashboard") return <Navigate to="/u/dashboard"/>
    if ((isLoading && data !== true) || user === false) return <Spinner fullHeight/>

    return children
}

export default function Root() {
    usePageViews()
    const {t} = useTranslation("register");
    const [user, setUser] = useState(false);
    const [error, setError] = useState(null);
    const isOnline = useOnlineStatus()
    const {mutate} = useSWRConfig()
    const maintenanceModeOn = useSWRSubscription('maintenanceModeOn', (_, {next}) => {
        /*if (LOCALHOST) {
            next(null, false)
            return () => {
            }
        }*/
        const listener = onSnapshot(doc(firestore, "app/finance"), {
            next: doc => {
                next(null, doc.get("maintenanceModeOn") || false)
            },
            error: err => {
                //console.error(err)
                next(new Error(err.message))
            }
        })
        return () => listener()
    })

    function onSendEmailVerificationLink() {
        setError(null)
        api.post(`/auth/sendEmailVerificationLink`)
            .then(res => {
                let error = null;
                if (!res.data) {
                    error = t('somethingWentWrongPleaseTryAgain');
                }
                setError(error)
            })
            .catch(err => {
                console.error(err)
                let error = t('somethingWentWrongPleaseTryAgain');
                setError(error)
            })
    }

    useEffect(() => {
        return onAuthStateChanged(auth, u => {
            resetAuthorizationHeaders()
            setUser(u)
        })
    }, []);

    return (
        <>
            {maintenanceModeOn.data ? (
                <>
                    <div className="h-full flex justify-center items-center bg-primary-500">
                        <JumperGame/>
                    </div>
                    <div
                        aria-live="assertive"
                        className="pointer-events-none fixed inset-0 flex px-4 py-6 items-end sm:p-6 z-50"
                    >
                        <div className="flex w-full flex-col items-center space-y-4 justify-center">
                            <Transition
                                show={isOnline}
                                as={Fragment}
                                enter="transform ease-out duration-300 transition"
                                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div
                                    className="pointer-events-auto w-full max-w-xl overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                                    <div className="p-4">
                                        <div className="flex items-start">
                                            <div className="flex-shrink-0">
                                                <ExclamationCircleIcon className="h-6 w-6 text-orange-400"
                                                                       aria-hidden="true"/>
                                            </div>
                                            <div className="ml-3 w-0 flex-1 pt-0.5">
                                                <p className="text-sm font-medium text-gray-900">{t('underMaintenanceThisMayTakeAFewMinutes')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Transition>
                        </div>
                    </div>
                    <div
                        aria-live="assertive"
                        className="pointer-events-none fixed inset-0 flex px-4 py-6 items-end sm:p-6 z-50"
                    >
                        <div className="flex w-full flex-col items-center space-y-4 justify-center">
                            <Transition
                                show={!isOnline}
                                as={Fragment}
                                enter="transform ease-out duration-300 transition"
                                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div
                                    className="pointer-events-auto w-full max-w-xl overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                                    <div className="p-4">
                                        <div className="flex items-start">
                                            <div className="flex-shrink-0">
                                                <InformationCircleIcon className="h-6 w-6 text-blue-400"
                                                                       aria-hidden="true"/>
                                            </div>
                                            <div className="ml-3 w-0 flex-1 pt-0.5">
                                                <p className="text-sm font-medium text-gray-900">{t('youAreOffline')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Transition>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <Routes>
                        <Route path="/u/*" element={
                            <RequireAuth user={user}>
                                <Suspense fallback={<Spinner fullHeight/>}>
                                    <LazyApp/>
                                </Suspense>
                            </RequireAuth>
                        }/>
                        <Route path="/register/*" element={
                            <RequireNonAuth user={user}>
                                <Register/>
                            </RequireNonAuth>
                        }/>

                        <Route path="/registration/complete" element={<RegisterFinal/>}/>
                        <Route path="/email-verified" element={<EmailVerified/>}/>

                        <Route path="/*" element={
                            <RequireNonAuth user={user}>
                                <Routes>
                                    <Route path="/lost-password" element={<LostPassword/>}/>
                                    <Route path="/sent-confirmation" element={<ConfirmationSent/>}/>
                                    <Route path="/accept-invitation/:id" element={<AcceptInvitation/>}/>
                                    <Route path="/verify-email" element={(
                                        <div className="h-full flex flex-col">
                                            <RegisterHeader/>
                                            <RegisterContent>
                                                <div className="Container Container--Stretch">
                                                    <div className="Register-Content-Header">
                                                        <h1 className="Register-Heading"><Trans
                                                            i18nKey="register:verifyYourEmail"
                                                            components={{
                                                                b: <b/>
                                                            }}
                                                        /></h1>
                                                        <div className="Register-Content-Body">
                                                            <div className="Register-Row">
                                                                <p>{t('toContinuePleaseVerifyYourEmailFirst')}</p>
                                                                <span
                                                                    className="Register-FormField-Helper Register-FormField-Helper--Error">{error}</span>
                                                            </div>
                                                            <div className="Register-Row"/>
                                                            <button type="button"
                                                                    onClick={onSendEmailVerificationLink}
                                                                    className="Button">
                                                                {t('sendEmailVerificationLink')}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </RegisterContent>
                                            <RegisterFooter/>
                                        </div>
                                    )}/>

                                    <Route path="/enroll" element={<Enroll2FA/>}/>
                                    <Route path="/quote-success" element={<Quote/>}/>
                                    <Route path="/quote/:step" element={<Quote/>}/>
                                    <Route path="/quote" element={<Navigate to="/quote/country"/>}/>
                                    <Route path="/signinas" element={<SignInAs/>}/>
                                    <Route path="/" element={<Login/>}/>
                                    <Route path="*" element={<RegisterRoot><NotFound/></RegisterRoot>}/>
                                </Routes>
                            </RequireNonAuth>
                        }/>
                    </Routes>
                    <div
                        aria-live="assertive"
                        className="pointer-events-none fixed inset-0 flex px-4 py-6 items-end sm:p-6 z-50"
                    >
                        <div className="flex w-full flex-col items-center space-y-4 justify-center">
                            <Transition
                                show={!isOnline}
                                as={Fragment}
                                enter="transform ease-out duration-300 transition"
                                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div
                                    className="pointer-events-auto w-full max-w-xl overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                                    <div className="p-4">
                                        <div className="flex items-start">
                                            <div className="flex-shrink-0">
                                                <InformationCircleIcon className="h-6 w-6 text-blue-400"
                                                                       aria-hidden="true"/>
                                            </div>
                                            <div className="ml-3 w-0 flex-1 pt-0.5">
                                                <p className="text-sm font-medium text-gray-900">{t('youAreOffline')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Transition>
                        </div>
                    </div>
                </>
            )}
            <div id="request-otp" className="hidden"/>
        </>
    )
}

function AcceptInvitation() {
    const {id} = useParams()
    sessionStorage && sessionStorage.setItem("invitationId", id)
    return <Navigate to={"/register"}/>
}

function EmailVerified() {
    const {t} = useTranslation("register")
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setGlobalLoader(false))
    }, [])

    return <div className="h-full flex flex-col">
        <RegisterHeader/>
        <RegisterContent>
            <div className="Container Container--Stretch">
                <p>{t('thankYouForVerifyingYourEmail')}</p>
                <div className="flex justify-center mt-6">
                    <Link to={"/"} type="button" className="Button">
                        {t('signIn')}
                    </Link>
                </div>
            </div>
        </RegisterContent>
        <RegisterFooter/>
    </div>
}

