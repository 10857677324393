import React from 'react';
import {createRoot} from 'react-dom/client';
import Root from './root';
import './i18n';
import * as serviceWorker from './serviceWorker';
import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import {Provider} from 'react-redux';
import store from './store';
import './interceptors'
import {BrowserRouter} from "react-router-dom";
import {SWRConfig} from "swr";
import {auth} from "./firebase";

function localStorageProvider() {
    // When initializing, we restore the data from `localStorage` into a map.
    const map = new Map(JSON.parse(sessionStorage.getItem('app-cache') || '[]'))

    // Before unloading the app, we write back all the data into `localStorage`.
    window.addEventListener('beforeunload', () => {
        if (auth.currentUser) {
            const appCache = JSON.stringify(Array.from(map.entries()))
            sessionStorage.setItem('app-cache', appCache)
        } else sessionStorage.removeItem('app-cache')
    })

    // We still use the map for write & read for performance.
    return map
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <I18nextProvider i18n={i18next}>
        <Provider store={store}>
            <BrowserRouter>
                <SWRConfig value={{provider: localStorageProvider}}>
                    <Root/>
                </SWRConfig>
            </BrowserRouter>
        </Provider>
    </I18nextProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
