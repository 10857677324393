import axios from "axios";

export const LOCALHOST = process.env.REACT_APP_LOCALHOST === "true";
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const FIREBASE_PROJECT = process.env.REACT_APP_FIREBASE_PROJECT;
export const FIREBASE_REGION = process.env.REACT_APP_FIREBASE_REGION;
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const HOST = process.env.REACT_APP_HOST;
export const API_URL = process.env.REACT_APP_API_URI;
export const SHARED_API_URL = process.env.REACT_APP_SHARED_API_URI;
export const INVEST_API = process.env.REACT_APP_INVEST_API;
export const RECAPTCHA_DEBUG_KEY = process.env.REACT_APP_RECAPTCHA_DEBUG_KEY;
export const RECAPTCHA = process.env.REACT_APP_RECAPTCHA;
export const FIREBASE_APPCHECK_DEBUG_MODE = process.env.FIREBASE_APPCHECK_DEBUG_MODE === "true";

export const api = axios.create({
    baseURL: SHARED_API_URL,
})
export const financeApi = axios.create({
    baseURL: SHARED_API_URL + "/finance",
})
export const investAPI = axios.create({
    baseURL: INVEST_API,
})

ENVIRONMENT !== "prod" && console.log("Running", ENVIRONMENT, "environment")
