import {createSlice} from '@reduxjs/toolkit';
import {financeApi} from "../config";
import {serializeQuery} from "../utils";

const initialState = {
    ordered: [],
    hasMore: false,
};

export const slice = createSlice({
    name: 'receivables',
    initialState,
    reducers: {
        setList: (state, action) => {
            const {ordered, hasMore} = action.payload;
            state.ordered = ordered;
            state.hasMore = hasMore;
        },
    }
});

// ACTIONS

const {setList} = slice.actions;


export const fetchIssued = (activeOrganizationId, params) => async (dispatch, getState) => {
    const state = getState()
    await financeApi.get("/invoices/" + activeOrganizationId + "/issued?" + serializeQuery(params)).then(({data}) => {
        //console.log("fetchInvoiceList", response.data)
        if (data?.results && data.results.length >= 0) {
            dispatch(setList({
                ordered: data.offset > 0 ? [...state.receivables.ordered, ...data.results] : [...data.results],
                hasMore: data.hasMore
            }));
        } else {
            dispatch(setList({
                ordered: [],
                hasMore: false
            }));
        }
    }).catch(error => {
        console.log("error", error)
    })
}

export const fetchIssuedDrafts = (activeOrganizationId, params) => async (dispatch, getState) => {
    const state = getState();
    await financeApi.get("/invoices/drafts/" + activeOrganizationId + "/issued?" + serializeQuery(params)).then(response => {
        //console.log("fetchInvoiceList", response.data)
        if (response.data?.results && response.data.results.length >= 0) {
            dispatch(setList({
                ordered: response.data.offset > 0 ? [...state.receivables.ordered, ...response.data.results] : [...response.data.results],
                hasMore: response.data.hasMore
            }));
        } else {
            dispatch(setList({
                ordered: [],
                hasMore: false
            }));
        }
    }).catch(error => {
        console.log("error", error)
    })
}

// SELECTORS


export const receivableListSelector = state => state.receivables.ordered;
export const receivableListHasMoreSelector = state => state.receivables.hasMore;

export default slice.reducer;
