import React from "react";
import {useTranslation} from "react-i18next";
import {RegisterRoot} from "./register";

export default function ConfirmationSent() {
    const {t} = useTranslation()

    return (
        <RegisterRoot>
            <h1 className="mb-4 font-medium text-3xl">{t('checkYourEmailTitle')}</h1>
            <span className="text-sm flex-col">{t("checkYourEmailText")}.</span>
        </RegisterRoot>
    )

}
