import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {auth} from "../firebase";
import {RegisterRoot} from "./register";
import {sendPasswordResetEmail} from "firebase/auth";
import {TextField} from "../components/textField";

export default function LostPassword() {
    const {t} = useTranslation()
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    function onSubmit(e) {
        e.preventDefault();
        sendPasswordResetEmail(auth, email).then(() => {
            setError("")
            setLoading(false)

            navigate("/sent-confirmation")
        }).catch(err => {
            console.error(err)
            if (err?.message) {
                setError(err.message)
            } else {
                setError("There was an error submitting your request")
            }
            setLoading(false)
        })
    }

    return (
        <RegisterRoot>
            <form onSubmit={onSubmit}>
                <h1 className="mb-4 font-medium text-3xl">{t('resetPasswordTitle')}</h1>
                <span className="text-sm flex-col">{t("resetPasswordText")}.</span>

                <div className="w-full flex flex-col justify-center items-center mt-10 gap-4">
                    <TextField
                        autoFocus={true}
                        name="email"
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        autoComplete="email"
                        placeholder={t("yourEmail")}
                        error={error}
                        required
                        disabled={loading}
                        fetching={loading}
                        hideSubmit={false}
                        wide
                    />
                </div>
            </form>
        </RegisterRoot>
    )
}
