import {createSlice} from '@reduxjs/toolkit';
import {api} from "../config";
import {serializeQuery} from "../utils";

const initialState = {
    ordered: [],
    hasMore: false,
};

export const slice = createSlice({
    name: 'partners',
    initialState,
    reducers: {
        setList: (state, action) => ({
            ...action.payload
        }),
    }
});

// ACTIONS

const {setList} = slice.actions;

export const fetchPartners = (activeOrganizationId, params) => async (dispatch, getState) => {
    const state = getState();
    await api.get("/organizations/" + activeOrganizationId + "/partners?" + serializeQuery(params)).then(response => {
        if (response.data?.results && response.data.results.length >= 0) {
            dispatch(setList({
                ordered: response.data.offset > 0 ? [...state.partners.ordered, ...response.data.results] : [...response.data.results],
                hasMore: response.data.hasMore
            }));
        } else {
            dispatch(setList({
                ordered: [],
                hasMore: false
            }));
        }
    }).catch(error => {
        console.log("error", error)
    })
}

// SELECTORS

export const partnerListSelector = state => state.partners.ordered;
export const partnerListHasMoreSelector = state => state.partners.hasMore;

export default slice.reducer;
