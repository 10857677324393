import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {auth} from "../firebase";
import {
    getMultiFactorResolver,
    multiFactor,
    PhoneAuthProvider,
    PhoneMultiFactorGenerator,
    RecaptchaVerifier,
    signInWithEmailAndPassword,
    signOut
} from "firebase/auth";
import {RegisterContent, RegisterFooter, RegisterHeader} from "./register";
import {classNames, useInterval} from "../utils";
import {TextField} from "../components/textField";

import OtpInput from 'react-otp-input';

let recaptchaWidgetId = null,
    recaptchaVerifier = null

function initRecaptcha() {
    if (!recaptchaVerifier) {
        recaptchaVerifier = new RecaptchaVerifier(auth, "request-otp", {
            'size': "invisible",
        })
        recaptchaVerifier.render().then((widgetId) => {
            recaptchaWidgetId = widgetId;
        });
    }
}

function ConfirmPhoneNumberView({onSubmit, onSendCode, onAccountSwitchClick, disabled}) {
    const {t} = useTranslation("register");
    const [code, setCode] = useState('')
    const [error, setError] = useState(null)
    const [timeout, setTimeout] = useState(-1);

    useEffect(() => {
        setCode("")
        setError(null)
        setTimeout(60)
    }, [])

    useInterval(() => {
        setTimeout(timeout => timeout - 1);
    }, timeout > -1 ? 1000 : null);

    useEffect(() => {
        if (timeout === 60) {
            //setFetching(true);
            onSendCode && onSendCode()
        }
    }, [timeout]);

    function _onSubmit(e) {
        e.preventDefault()

        if (!code) {
            setError(t('pleaseEnterTheCode'))
            return
        }
        if (code.length !== 6) {
            setError(t('pleaseEnterTheCode'))
            return
        }

        onSubmit && onSubmit(code)
    }

    function onChange(v) {
        setCode(v)
        setError(null)

        if (v.length === 6) {
            onSubmit && onSubmit(v)
        }
    }

    return (
        <form onSubmit={_onSubmit}>
            <h1 className="mb-4 font-medium text-3xl">{t('securityVerification')}</h1>
            <span className="text-sm flex-col">{t('enter6digitCodeSentToYourPhone')}</span>
            <div className="w-full flex flex-col justify-center items-center mt-10 gap-4">
                <OtpInput
                    value={code}
                    onChange={onChange}
                    numInputs={6}
                    isInputNum={true}
                    inputType={"number"}
                    shouldAutoFocus={true}
                    containerStyle="flex justify-center w-full gap-2 mb-4"
                    inputStyle={classNames(
                        "appearance-none min-w-[48px] min-h-[48px] border-2 font-semibold rounded-lg !bg-primary-500 !autofill:bg-primary-500 py-1.5 text-white disabled:text-primary-200 placeholder:text-primary-200 shadow-sm focus:ring-0 text-xl sm:leading-6",
                        disabled ? "text-primary-200 border-primary-400" : error ? "border-red-500 hover:border-red-500 focus:border-red-500" : "border-primary-200 hover:border-primary-200 focus:border-primary-200",
                    )}
                    separator={<span>&nbsp;</span>}
                    renderInput={(props) => <input {...props} disabled={disabled} pattern="[0-9]*"/>}/>

                <div>
                    <button type="button"
                            onClick={() => timeout <= 0 ? setTimeout(60) : ''}
                            className="Button Button--Light"
                            disabled={timeout > 0 || disabled}>
                        {t('sendSmsCode')}
                        {timeout > -1 ? <div style={{margin: "0px 4px 0px 8px"}}>
                            <div style={{
                                opacity: 0.6,
                                display: "inline-flex",
                                justifyContent: "center",
                                width: "12px",
                                height: "12px",
                                textAlign: "center",
                                lineHeight: "8px",
                            }}>{timeout}</div>
                        </div> : ""}
                    </button>
                </div>

                <div>
                    <button type="button" onClick={onAccountSwitchClick} className="Button">
                        {t('switchAccount')}
                    </button>
                </div>
            </div>
        </form>
    )
}

export function Login() {
    const {t} = useTranslation("register");
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [view, setView] = useState("login") // "login" | "confirm-2fa"
    const [fetching, setFetching] = useState(false);
    const [multiFactorResolver, setMultiFactorResolver] = useState(null);
    const [verificationId, setVerificationId] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passVisible, setPassVisible] = useState(false)
    const [hasVerified, setHasVerified] = useState(false);

    useEffect(() => {
        switch (view) {
            case "login":
                initRecaptcha()
                break;
            case "confirm-2fa":
                //initRecaptcha()
                break;
            default:
                setView("login")
        }
    }, [view])

    async function verifyPhoneNumber() {
        if (!(multiFactorResolver?.hints && multiFactorResolver?.hints[0])) {
            setView("login")
            return
        }

        function _callback() {
            if (hasVerified) return
            if (!multiFactorResolver.hints[0] || !multiFactorResolver.session) return
            const phoneInfoOptions = {
                multiFactorHint: multiFactorResolver.hints[0],
                session: multiFactorResolver.session
            }

            setError(null)
            setHasVerified(true)

            if (!window._phoneAuthProvider) window._phoneAuthProvider = new PhoneAuthProvider(auth);
            return window._phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier).then(verificationId => {
                setFetching(false)
                setVerificationId(verificationId)
                setHasVerified(true)
                return verificationId
            }).catch(err => {
                console.error("verifyPhoneNumber error:", err)
                setFetching(false)
                setError(err.message)
                setHasVerified(false)
            })
        }

        return _callback()
    }

    function logout() {
        signOut(auth).finally(() => window.location.reload())
    }

    function onLoginSubmit(e) {
        e.preventDefault();

        setFetching(true)

        signInWithEmailAndPassword(auth, email, password)
            .then(userRec => {
                const user = userRec.user

                setFetching(false)
                setError(null)

                if (user?.emailVerified && multiFactor(user).enrolledFactors?.length > 0) {
                    setView("confirm-2fa")
                } else if (user?.emailVerified && multiFactor(user).enrolledFactors?.length === 0) {
                    navigate("/enroll")
                } else if (!user) {
                    setError(t('thereWasAnErrorProcessingYourRequestPleaseRefreshT'))
                } else if (!user?.emailVerified) {
                    navigate("/verify-email")
                }
            })
            .catch(err => {
                let error;
                switch (err.code) {
                    case "auth/multi-factor-auth-required":
                        setMultiFactorResolver(getMultiFactorResolver(auth, err))
                        setView("confirm-2fa")
                        break;
                    case "auth/user-disabled":
                        error = t('thisUserAccountHasBeenDisabled')
                        break
                    case "auth/wrong-password":
                        error = t('thePasswordIsInvalidOrTheUserDoesNotExist')
                        break
                    default:
                        console.error(err)
                        error = err.message
                }
                setFetching(false)
                setError(error)
            });
    }

    async function onConfirm2FASubmit(code) {
        if (!verificationId) {
            setError(t('somethingWentWrongPleaseRefreshThePageAndTryAgain'))
            return
        }

        // Ask user for the verification code. Then:
        const cred = PhoneAuthProvider.credential(verificationId, code),
            multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

        setFetching(true)

        // Complete sign-in. This will also trigger the Auth state listeners.
        multiFactorResolver.resolveSignIn(multiFactorAssertion)
            .then(userCredential => {
                setFetching(false)
                setError(null)
            })
            .catch(err => {
                setFetching(false)
                console.error("onConfirm2FASubmit", err)
                if (err?.message) {
                    setError(err.message)
                } else {
                    setError(t('somethingWentWrongPleaseRefreshThePageAndTryAgain'))
                }
            })
    }

    return (
        <div className="h-full flex flex-col">
            <RegisterHeader/>
            <div
                className="flex-1 relative bg-primary-500 text-white sm:flex sm:justify-center sm:items-center">
                <div className="w-full mx-auto max-w-md my-16 px-8 text-left">
                    {view === "login" && (
                        <form className="dark" onSubmit={onLoginSubmit}>
                            <h1 className="mb-4 font-medium text-3xl">{t('logInToYourAccount')}</h1>
                            <span className="text-sm flex-col">{t("registerInsteadText")}{" "}<Link
                                to="/register/business-country"
                                className="font-semibold">{t("registerInsteadLink")}</Link>.</span>
                            <div className="w-full flex flex-col justify-center items-center mt-10 gap-4">
                                <div className="w-full">
                                    <TextField
                                        autoFocus={false}
                                        name="username"
                                        type="email"
                                        onChange={e => setEmail(e.target.value)}
                                        autoComplete="email"
                                        placeholder={t("usernamePlaceholder")}
                                        error={!!error}
                                        value={email}
                                        disabled={fetching}
                                        fetching={fetching}
                                        required
                                        hideSubmit={true}
                                        wide
                                    />
                                    <TextField
                                        autoFocus={false}
                                        name="password"
                                        type={passVisible ? "text" : "password"}
                                        onChange={e => setPassword(e.target.value)}
                                        autoComplete="current-password"
                                        placeholder={t("passwordPlaceholder")}
                                        error={error}
                                        value={password}
                                        disabled={fetching}
                                        fetching={fetching}
                                        required
                                        hideSubmit={true}
                                        wide
                                    />
                                </div>

                                <button
                                    type="submit"
                                    className="flex w-full justify-center rounded-md bg-primary-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                                >
                                    {t("login")}
                                </button>

                                <div>
                                    <Link className="text-sm font-semibold"
                                          to={"/lost-password"}>{t("resetPasswordHere")}</Link>
                                </div>
                            </div>
                        </form>
                    )}
                    {view === "confirm-2fa" && (
                        <ConfirmPhoneNumberView
                            t={t}
                            onSubmit={onConfirm2FASubmit}
                            onSendCode={verifyPhoneNumber}
                            onAccountSwitchClick={logout}
                            disabled={fetching || !recaptchaWidgetId}
                            error={error}
                        />
                    )}
                </div>
            </div>
            <RegisterFooter/>
        </div>
    )
}

export function Enroll2FA() {
    const {t} = useTranslation("register");
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [view, setView] = useState("enable-2fa") // "enable-2fa" | "confirm-2fa"
    const [fetching, setFetching] = useState(false);
    const [verificationId, setVerificationId] = useState('');
    const [phone, setPhone] = useState('');
    const [hasVerified, setHasVerified] = useState(false);
    //const [selectedCountryCode, setSelectedCountryCode] = useState(countryPhoneCodes.find(c => c.code === "SI"));
    //const [query, setQuery] = useState('')

    useEffect(() => {
        if (!auth.currentUser) {
            navigate("/")
            return
        }
        setError(null)
        switch (view) {
            case "enable-2fa":
                initRecaptcha()
                break;
            case "confirm-2fa":
                //setTimeout(60)
                break;
            default:
                setView("enable-2fa")
        }
    }, [view])

    async function verifyPhoneNumber() {
        if (!(auth.currentUser && phone)) {
            navigate("/")
            return
        }

        const session = await multiFactor(auth.currentUser).getSession().catch(err => {
            setError("There was an error while trying to get the session. Please try again later")
            return null
        })

        if (!session) {
            navigate("/")
            return
        }

        function _callback() {
            if (hasVerified) return
            if (!phone || !session) return
            const phoneInfoOptions = {
                phoneNumber: phone,
                session
            }

            setError(null)
            setHasVerified(true)

            if (!window._phoneAuthProvider) window._phoneAuthProvider = new PhoneAuthProvider(auth);
            return window._phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier).then(verificationId => {
                setFetching(false)
                setVerificationId(verificationId)
                setHasVerified(true)
                return verificationId
            }).catch(err => {
                console.error("verifyPhoneNumber error:", err)
                if (err.code === "auth/requires-recent-login") {
                    logout()
                }
                setFetching(false)
                setError(err.message)
                setHasVerified(false)
            })
        }

        return _callback()
    }

    function logout() {
        signOut(auth).finally(() => window.location.reload())
    }

    const phoneNumberRegex = /^\+\d{1,3}\d{10}$/;

    function onEnable2FASubmit(e) {
        e.preventDefault();

        if (!phone) {
            setError(t("pleaseEnterAValidPhoneNumber"))
            return
        }

        const _phone = phone.replace(/\s/g, '').replace(/-/g, '')

        if (!_phone.startsWith("+") && !_phone.startsWith("00")) {
            setError(t("pleaseEnterAValidPhoneNumber"))
            return
        }

        if (_phone.match(phoneNumberRegex)) {
            setPhone(_phone)
            setView("confirm-2fa")
        } else {
            setError(t("pleaseEnterAValidPhoneNumber"))
        }
    }

    async function onConfirm2FASubmit(code) {
        if (!verificationId || !auth.currentUser) {
            setError(t('somethingWentWrongPleaseRefreshThePageAndTryAgain'))
            return
        }

        // Ask user for the verification code. Then:
        const cred = PhoneAuthProvider.credential(verificationId, code),
            multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

        setFetching(true)

        multiFactor(auth.currentUser).enroll(multiFactorAssertion, "Default").then(() => {
            setFetching(false)
            setError(null)
            navigate("/u/dashboard", {replace: true})
        }).catch(err => {
            setFetching(false)
            console.error(err)
            if (err?.message) {
                setError(err.message)
            } else {
                setError(t('somethingWentWrongPleaseRefreshThePageAndTryAgain'))
            }
        })
    }

    return (
        <div className="h-full flex flex-col">
            <RegisterHeader/>
            <RegisterContent>
                <div className="m-auto px-8 sm:px-16 md:px-0">
                    {view === "enable-2fa" && (
                        <form onSubmit={onEnable2FASubmit}>

                            <h1 className="mt-16 font-medium text-center">{t("setupTwoFactor")}<b/></h1>
                            <div className="flex flex-col justify-center items-center pt-8 pb-4">
                                <TextField
                                    autoFocus={true}
                                    name="phone"
                                    type="tel"
                                    autoComplete="tel"
                                    disabled={fetching}
                                    placeholder={t("enterYourPhoneNumber")}
                                    onChange={e => setPhone(e.target.value)}
                                    value={phone}
                                    required
                                    error={error}
                                    hideSubmit={false}
                                />
                            </div>
                        </form>
                    )}
                    {view === "confirm-2fa" && (
                        <ConfirmPhoneNumberView
                            t={t}
                            onSubmit={onConfirm2FASubmit}
                            onSendCode={verifyPhoneNumber}
                            onAccountSwitchClick={logout}
                            disabled={fetching || !recaptchaWidgetId}
                            error={error}
                        />
                    )}
                </div>
            </RegisterContent>
            <RegisterFooter/>
        </div>
    )
}

