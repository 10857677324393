import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {ENVIRONMENT} from "./config";

export function downloadDocument(data, fileName) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(new Blob([data]));
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.addEventListener('click', (e) => {
        return false
    })
    link.click();
    link.remove();
}

export function useErrorHandler() {
    //const [error, setError] = React.useState(null);

    function handleError(e) {
        catchErrors(e, window.alert);
    }

    return handleError;
}

export function catchErrors(error, cb) {
    error = error || {}
    let message
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.body);
        console.log(error.response.status);
        console.log(error.response.headers);

        if (error.response.data?.message) message = error.response.data.message
        else if (error.response.data?.error) message = error.response.data.error
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request)
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
        if (error.message) message = error.message
    }
    //console.log(error.config);
    cb && cb(message)
    return message
}

export const daysBetween = (startDate, endDate) => {
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    return Math.floor((endDate - startDate) / millisecondsPerDay).toFixed(0);
}
/*export const daysBetween = (startDate, endDate) => {
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay;
}*/

export const daysFromNow = endDate => daysBetween(Date.now(), endDate)

export function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export function validReferenceNumber(str) {
    if (typeof str != "string") return false // we only process strings!
    const spl = str.split("-")
    for (let i = 0; i < spl.length; i++) {
        if (!isNumeric(spl[i])) return false
    }
    return true
}

/**
 * Date string in format DD.MM.YYYY
 * @param date
 */
export const getDateString = date => {
    if (!date) return null
    date = typeof date === 'string' ? new Date(date) : date
    let m = "" + (date.getMonth() + 1).toString(), d = "" + date.getDate()
    if (m.length === 1) m = "0" + m
    if (d.length === 1) d = "0" + d
    return `${d}. ${m}. ${date.getFullYear()}`
}

export const getFirestampDateString = fs => {
    return getDateString(getFirestampDate(fs))
}

export const getFirestampDatetimeString = fs => {
    return getDatetimeString(getFirestampDate(fs))
}


export const getFirestampDate = fs => {
    return fs?._seconds ? new Date(fs._seconds * 1000) : null
}


/**
 * Datetime string in format DD.MM.YYYY HH:MM
 * @param date
 */
export const getDatetimeString = date => {
    if (!date) return null
    date = typeof date === 'string' ? new Date(date) : date
    let h = "" + date.getHours(), m = "" + date.getMinutes()
    if (h.length === 1) h = "0" + h
    if (m.length === 1) m = "0" + m
    return `${getDateString(date)} ${h}:${m}`
}


export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const decimal = ",", thousands = "."
export const formatMoney = (amount, forgetDecimals = false) => {
    try {
        let decimalCount = forgetDecimals ? 0 : 2
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        const decs = Math.abs(amount - i)

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (!forgetDecimals || (forgetDecimals && decs !== 0) ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
}

export function formatCurrency(num, locale = "sl-SI") {
    return new Intl.NumberFormat(locale, {
        style: "currency",
        currency: "EUR",
        signDisplay: "negative",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(num);
}

export function formatPercent(num, locale = "sl-SI") {
    return new Intl.NumberFormat(locale, {
        style: "unit",
        unit: "percent",
        signDisplay: "negative",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    }).format(num);
}

export const formatDate = ts => {
    const d = new Date(ts)
    const ye = new Intl.DateTimeFormat('sl', {year: 'numeric'}).format(d);
    const mo = new Intl.DateTimeFormat('sl', {month: '2-digit'}).format(d);
    const da = new Intl.DateTimeFormat('sl', {day: '2-digit'}).format(d);
    return `${da}${mo}.${ye}`
}

const pad = (num, size) => {
    let s = String(num)
    while (s.length < size) s = "0" + s
    return s
}

export const formatTimestamp = ts => {
    const d = new Date(ts)
    const ye = new Intl.DateTimeFormat('sl', {year: 'numeric'}).format(d);
    const mo = new Intl.DateTimeFormat('sl', {month: '2-digit'}).format(d);
    const da = new Intl.DateTimeFormat('sl', {day: '2-digit'}).format(d);
    return `${pad(d.getHours(), 2)}:${pad(d.getMinutes(), 2)}:${pad(d.getSeconds(), 2)} ${da}${mo}.${ye}`
}

export const isMobile = () => {
    return window.outerWidth <= 479
}

export const treatAsUTC = (date) => {
    const result = new Date(date);
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
    return result;
}


export const dateFormatter = millis => {
    if (isNaN(millis) || millis === null) return '';
    const v = new Date(millis);
    if (!(v instanceof Date)) return '';
    const pad = '00';
    const yy = v.getUTCFullYear().toString();
    const mm = (v.getUTCMonth() + 1).toString();
    const dd = v.getUTCDate().toString();
    return `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`;
};

export const dateParser = v => {
    // v is a string of "YYYY-MM-DD" format
    const match = /(\d{4})-(\d{2})-(\d{2})/.exec(v);
    if (match === null) return;
    const d = new Date(Date.UTC(match[1], parseInt(match[2], 10) - 1, match[3]));
    if (isNaN(d)) return;
    return d;
};

export const sloDateParser = v => {
    // v is a string of "DD MM YY" format
    const match = /^(\d{1,2})[-.\/](\d{1,2})[-.\/](\d{2,4})$/gm.exec(v);
    if (match === null) return;
    const d = new Date(Date.UTC(match[3], parseInt(match[2], 10) - 1, match[1]));
    if (isNaN(d)) return;
    return d;
};

function comma(address) {
    return address.length > 0 ? ", " : ""
}

export function formatAddress(addressObject) {
    if (!addressObject) return null
    let address = ""
    if (addressObject.address1) address += addressObject.address1
    else if (addressObject.street) {
        address += addressObject.street + " "
        if (addressObject.houseNumber) address += addressObject.houseNumber
    } else if (addressObject.streetAndNumber) address += addressObject.streetAndNumber
    if (addressObject.postCode) address += comma(address) + addressObject.postCode + " "
    if (address && addressObject.city) address += addressObject.city
    if (address && addressObject.country?.name) address += ", " + addressObject.country?.name
    return address
}

// function that gets the next working day
export function getNextWorkingDay(date) {
    const day = date.getDay();
    if (day === 5) {
        date.setDate(date.getDate() + 3);
    } else if (day === 6) {
        date.setDate(date.getDate() + 2);
    } else {
        date.setDate(date.getDate() + 1);
    }
    return date;
}

export function useInterval(callback, delay) {
    const intervalId = React.useRef(null);
    const savedCallback = React.useRef(callback);
    React.useEffect(() => {
        savedCallback.current = callback;
    });
    React.useEffect(() => {
        const tick = () => savedCallback.current();
        if (typeof delay === 'number') {
            intervalId.current = window.setInterval(tick, delay);
            return () => window.clearInterval(intervalId.current);
        }
    }, [delay]);
    return intervalId.current;
}

export function serializeQuery(params, prefix) {
    if (!params) return ""
    const query = Object.keys(params).map((key) => {
        const value = params[key];
        if (params.constructor === Array) key = `${prefix}[${key}]`;
        else if (params.constructor === Object) key = (prefix ? `${prefix}[${key}]` : key);
        if (typeof value === 'object') return serializeQuery(value, key);
        else return `${key}=${encodeURIComponent(value)}`;
    })
    return [].concat.apply([], query).join('&');
}

// gets the right word for the given number - singular, plural, etc.
export function getWord(n, ...words) {
    const one = words[0] || "",
        two = words[1] || one,
        threeOrFour = words[2] || two,
        zeroOrMany = words[3] || threeOrFour

    switch (n) {
        case 1:
            return one
        case 2:
            return two
        case 3:
        case 4:
            return threeOrFour
        default:
            return zeroOrMany
    }
}

export function getWordTranslated(t, n, ...words) {
    return t(getWord(n, ...words))
}

export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export async function importXLSX(arrayBuffer, ...columnParsers) {
    const data = [],
        errors = [],
        XLSX = await import("xlsx")

    let workbook, sheetKeys, ws, jsa

    try {
        workbook = XLSX.read(arrayBuffer, {type: 'binary', cellDates: true, dateNF: 'dd/mm/yyyy;@'})
    } catch (e) {
        errors.push({
            type: "error",
            code: "importXLSX.readError",
            message: e.message
        })
        return {data, errors}
    }

    try {
        sheetKeys = Object.keys(workbook.Sheets)

        if (sheetKeys.length === 0) {
            errors.push({
                type: "error",
                code: "importXLSX.sheetKeysEmptyError",
                message: "No sheets found"
            })
            return {data, errors}
        } else if (sheetKeys.length > 1) errors.push({
            type: "warning",
            code: "importXLSX.multipleSheets",
            message: "Multiple sheets found. Only the first sheet will be imported.",
        })
    } catch (e) {
        errors.push({
            type: "error",
            code: "importXLSX.sheetKeysError",
            message: e.message
        })
        return {data, errors}
    }

    try {
        ws = workbook.Sheets[sheetKeys[0]]
    } catch (e) {
        errors.push({
            type: "error",
            code: "importXLSX.sheetError",
            message: e.message
        })
        return {data, errors}
    }

    try {
        jsa = XLSX.utils.sheet_to_json(ws, {header: 1, raw: false})
    } catch (e) {
        errors.push({
            type: "error",
            code: "importXLSX.sheetToJsonError",
            message: e.message
        })
        return {data, errors}
    }


    for (let i = 0; i < jsa.length; i++) {
        const row = jsa[i], obj = {}
        if (row.length === 0) continue
        if (row.length !== columnParsers.length) {
            errors.push({
                type: "error",
                code: "parse.invalidNumberOfColumns",
                message: "Invalid number of columns at row " + (i + 1),
            })
            continue
        }
        for (let j = 0; j < columnParsers.length; j++) {
            const parser = columnParsers[j]
            try {
                parser(obj, row[j])
            } catch (e) {
                errors.push({
                    type: "error",
                    code: "parse.errorParsingValue",
                    message: "Error at row[" + (i + 1) + "] column[" + (j + 1) + "] " + e.message,
                })
            }
        }
        data.push({
            _id: "" + i + Date.now(),
            ...obj
        })
    }


    return [data, errors]
}

export function toCurrency(str, leaveOutSymbol) {
    str = "" + str || ""
    const formatter = new Intl.NumberFormat("sl-SI", {
        style: leaveOutSymbol ? "decimal" : "currency",
        currency: "EUR"
    })
    return formatter.format(str.replace(",", ".")) || "";
}

export function getWeekNumber(date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}

export function getLastWeeksMap(value, weeks = 52) {
    const today = new Date();
    const weeksMap = new Map();

    for (let i = weeks; i > -1; i--) {
        const date = new Date(today - i * 7 * 24 * 60 * 60 * 1000);
        const weekNumber = getWeekNumber(date);
        const year = date.getFullYear().toString().slice(-2);
        const key = `${weekNumber} / ${year}`;

        weeksMap.set(key, value);
    }

    return weeksMap;
}

export function getLastMonthsMap(value, months = 12) {
    const today = new Date();
    const monthsMap = new Map();

    for (let i = months; i > -1; i--) {
        const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
        const month = date.toLocaleString('default', {month: 'short'});
        const year = date.getFullYear().toString().slice(-2);
        const key = `${month} ${year}`;

        monthsMap.set(key, value);
    }

    return monthsMap;
}

export function getYearBackDate() {
    const d = new Date()
    d.setFullYear(d.getFullYear() - 1)
    d.setDate(1)
    return d
}


export function generateWeekKeyForDate(date) {
    const weekNumber = getWeekNumber(date);
    const year = date.getFullYear().toString().slice(-2);
    return `${weekNumber} / ${year}`;
}


export function generateMonthKeyForDate(date) {
    const month = date.toLocaleString('default', {month: 'short'});
    const year = date.getFullYear().toString().slice(-2);
    return `${month} ${year}`;
}

export function useQuery() {
    const {search} = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function usePageViews() {
    const location = useLocation(),
        enabled = (ENVIRONMENT === "prod" || ENVIRONMENT === "test")
    useEffect(() => {
        if (enabled && window.ga) {
            window.ga('set', 'page', location.pathname + location.search);
            window.ga('send', 'pageview');
            //window.ga('send', 'pageview', location.pathname + location.search);
        }
    }, [location]);
}

function fallbackCopyTextToClipboard(text) {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
        if (successful) return text
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
}

export function copyTextToClipboard(text) {
    if (!navigator.clipboard) return fallbackCopyTextToClipboard(text)
    return navigator.clipboard.writeText(text).then(() => {
        console.log('Async: Copying to clipboard was successful!');
        return text
    }, (err) => {
        console.error('Async: Could not copy text: ', err);
    })
}
