import {Fragment, useEffect, useState} from 'react'
import {Listbox, Transition} from '@headlessui/react'
import {CheckIcon} from '@heroicons/react/20/solid'
import {useTranslation} from "react-i18next";
import {GlobeAltIcon} from "@heroicons/react/24/outline";

const langs = [
    {
        id: "sl",
        name: 'Slovenščina',
    },
    {
        id: "en",
        name: 'English',
    },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function LanguageSelector() {
    const {t, i18n} = useTranslation('register')
    const [selected, setSelected] = useState(langs.find((person) => person.id === i18n.language) || langs[0])

    useEffect(() => {
        if (selected?.id !== i18n.language) i18n.changeLanguage(selected.id)
    }, [selected]);

    return (
        <Listbox value={selected} onChange={setSelected}>
            {({open}) => (
                <>
                    <Listbox.Label className="sr-only">{t("language")}</Listbox.Label>
                    <div className="relative text-left">
                        <Listbox.Button
                            className="relative w-full cursor-default rounded-md bg-primary-500 py-1.5 pl-10 pr-3 text-left text-primary-100 font-semibold ring-0 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6"><span
                            className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2"><GlobeAltIcon
                            className="h-5 w-5 text-primary-300" aria-hidden="true"/></span><span
                            className="block truncate">{selected.name}</span></Listbox.Button>
                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options
                                className="min-w-[170px] absolute right-0 bottom-0 z-10 mb-10 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {langs.map((lang) => (
                                    <Listbox.Option
                                        key={lang.id}
                                        className={({active}) =>
                                            classNames(
                                                active ? 'bg-primary-600 text-white' : 'text-gray-900',
                                                'relative cursor-default select-none py-2 pl-3 pr-9'
                                            )
                                        }
                                        value={lang}
                                    >
                                        {({selected, active}) => (
                                            <>
                                                <span
                                                    className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}
                                                    lang={selected.id !== lang.id ? lang.id : undefined}>{lang.name}</span>
                                                {selected ? (
                                                    <span
                                                        className={classNames(
                                                            active ? 'text-white' : 'text-primary-600',
                                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                                        )}
                                                    ><CheckIcon className="h-5 w-5" aria-hidden="true"/></span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    )
}
