import {createSlice} from '@reduxjs/toolkit';
import {api, financeApi} from "../config";

const initialState = {
    activeCid: "last", // it is "last" until user decides to switch client
    activeDetails: {}, // active client details
    integrations: {
        accounting: false,
    },
    users: []
}

export const slice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        setActive: (state, action) => {
            state.activeCid = action.payload
        },
        setData: (state, action) => {
            state[action.payload.id] = action.payload
        },
        setIntegrations: (state, action) => {
            state.integrations = action.payload
        },
        setUsers: (state, action) => {
            state.users = action.payload
        },
    }
});

// ACTIONS

const {setActive, setData, setIntegrations, setUsers} = slice.actions;

export const setActiveCid = (organizationId) => setActive(organizationId)

export const fetchClient = organizationId => async (dispatch) => {
    api.get("/organizations/" + organizationId).then(res => {
        if (res.status === 200 && res.data) {
            dispatch(setData({...res.data}))
        }
    }).catch(error => {
        console.log("error fetching organization details", error)
    })
}

export const fetchOrganizationIntegrations = organizationId => async (dispatch) => {
    financeApi.get("/organizations/" + organizationId + "/integrations").then(res => {
        if (res.status === 200 && res.data) {
            dispatch(setIntegrations(res.data))
        }
    }).catch(error => {
        console.log("error fetching organization integrations", error)
    })
}

export const removeOrganizationIntegrations = (organizationId, integration, deleteEntries) => async (dispatch) => {
    financeApi.delete("/organizations/" + organizationId + "/integrations/" + integration + "?" + (deleteEntries ? "deleteEntries=true&deleteEntriesConfirmed=true" : "")).then(res => {
        dispatch(fetchOrganizationIntegrations(organizationId))
    }).catch(error => {
        console.log("error deleting organization integration", error)
    })
}

export const fetchOrganizationUsers = organizationId => async (dispatch) => {
    api.get("/organizations/" + organizationId + "/users").then(res => {
        if (res.status === 200 && res.data?.results) {
            dispatch(setUsers(res.data.results))
        }
    }).catch(error => {
        console.log("error fetching organization details", error)
    })
}

// SELECTORS

export const activeCidSelector = state => state.client.activeCid || "last";
export const clientSelector = id => state => state.client[id] || null;
export const organizationIntegrationsSelector = state => state.client.integrations || null;
export const organizationUsersSelector = state => state.client.users || [];

export default slice.reducer;
