import {api, financeApi, investAPI} from "./config";
import {callApiWithAppCheckExample, callApiWithAuthorizationHeader} from "./firebase";
import i18n from "i18next";

export function setInvestHeaders(token, investUserId) {
    investAPI.defaults.headers.common['Authorization'] = "Bearer " + token;
    investAPI.defaults.headers.common['Userid'] = investUserId
}

export function resetAuthorizationHeaders() {
    api.defaults.headers.common['Authorization'] = ""
    financeApi.defaults.headers.common['Authorization'] = ""
    investAPI.defaults.headers.common['Authorization'] = "";
    investAPI.defaults.headers.common['Userid'] = "";
}

/*i18n.on('languageChanged', lng => {
    financeApi.defaults.headers.common['Accept-Language'] = lng
    api.defaults.headers.common['Accept-Language'] = lng
})*/

async function requestInterceptor(config) {
    // language
    config.headers['Accept-Language'] = i18n?.language || i18n?.options?.fallbackLng[0] || "en";

    // app check
    await callApiWithAppCheckExample(config)

    // authorization header
    await callApiWithAuthorizationHeader(config)

    return config;
}


function requestErrorInterceptor(error) {
    return Promise.reject(error);
}

function responseInterceptor(response) {
    return response;
}

function responseErrorInterceptor(error) {
    if (error.response?.status === 401) {
        window.location.reload()
    }
    return Promise.reject(error);
}


api.interceptors.request.use(requestInterceptor, requestErrorInterceptor);
financeApi.interceptors.request.use(requestInterceptor, requestErrorInterceptor);
investAPI.interceptors.request.use(requestInterceptor, requestErrorInterceptor);

api.interceptors.response.use(responseInterceptor, responseErrorInterceptor);
financeApi.interceptors.response.use(responseInterceptor, responseErrorInterceptor);
//investAPI.interceptors.response.use(responseInterceptor);
