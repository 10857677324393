import React from 'react'
import {classNames} from "../utils";

export default function TailSpinner({className, big, tiny}) {
    return (
        <svg className={classNames(
            "animate-spin",
            className,
            big ? "ml-0 mr-0 h-6 w-6" : tiny ? "m-0 w-4 h-4" : "-ml-1 mr-3 h-5 w-5"
        )} xmlns="http://www.w3.org/2000/svg" fill="none"
             viewBox="0 0 24 24">
            <circle className="opacity-[15%]" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
            <path className="opacity-[30%]" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
        </svg>
    )
}
